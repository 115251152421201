import OpalVertices from "./vertices";

const LOGO_LINE_WIDTH = 1703.6;
const LOGO_LINE_HEIGHT = 100.7;
const LOGO_SPRITE_URL = new URL("images/logo.svg", import.meta.url);

const Engine = Matter.Engine,
  Render = Matter.Render,
  Runner = Matter.Runner,
  Bodies = Matter.Bodies,
  Common = Matter.Common,
  Composite = Matter.Composite,
  Body = Matter.Body,
  Vertices = Matter.Vertices;

Bodies.capsule = function (x, y, radius, height, options, rotation, maxSides) {
  options = options || {};

  maxSides = maxSides || 26;
  var sides = Math.ceil(Math.max(6, Math.min(maxSides, radius)));
  sides = sides % 2 === 1 ? sides++ : sides;

  var halfSides = sides / 2,
    halfDiff = (height - radius) / 2,
    theta = (2 * Math.PI) / sides,
    path = "",
    angOffset = Math.PI + theta * 0.5,
    angle,
    xx,
    yy,
    yOffset;

  halfDiff = halfDiff < 0 ? 0 : halfDiff;

  for (var i = 0; i < sides; i++) {
    yOffset = i > halfSides ? halfDiff : -halfDiff;
    angle = angOffset + i * theta;
    xx = Math.cos(angle) * radius;
    yy = Math.sin(angle) * radius + yOffset;
    if (i == 0) {
      path += "L " + xx.toFixed(3) + " " + (yy - yOffset * 2).toFixed(3) + " ";
    }
    path += "L " + xx.toFixed(3) + " " + yy.toFixed(3) + " ";
    if (i == halfSides) {
      path += "L " + xx.toFixed(3) + " " + (yy - yOffset * 2).toFixed(3) + " ";
    }
  }
  var createCapsule = {
    label: "createCapsule Body",
    position: { x: x, y: y },
    vertices: Vertices.fromPath(path),
  };

  if (rotation != null || rotation % (Math.PI * 2) == 0) {
    Vertices.rotate(createCapsule.vertices, rotation, { x: x, y: y });
  }

  return Body.create(Common.extend({}, createCapsule, options));
};

const engine = Engine.create();
const runner = Runner.create();

const canvas = document.getElementById("matter-container");

canvas.style.display = "block";

var VIEW = {};
VIEW.width = canvas.getBoundingClientRect().width;
VIEW.height = canvas.getBoundingClientRect().height;
VIEW.centerX = VIEW.width / 2;
VIEW.centerY = VIEW.height / 2;

var scaleFactor = (VIEW.width * 0.98) / LOGO_LINE_WIDTH;

var isLarge = canvas.getBoundingClientRect().width >= 1024;
var isMedium = canvas.getBoundingClientRect().width >= 768;

const render = Render.create({
  element: canvas,
  engine: engine,
  options: {
    width: VIEW.width,
    height: VIEW.height,
    wireframes: false,
    background: "transparent",
    pixelRatio: window.devicePixelRatio,
  },
});

// Add boundaries
const boundaryLeft = Bodies.rectangle(-1, VIEW.height / 2, 1, VIEW.height, {
  render: {
    strokeStyle: "transparent",
    fillStyle: "transparent",
  },
  isStatic: true,
});
const boundaryRight = Bodies.rectangle(
  VIEW.width + 1,
  VIEW.height / 2,
  1,
  VIEW.height,
  {
    render: {
      strokeStyle: "transparent",
      fillStyle: "transparent",
    },
    isStatic: true,
  },
);
const boundaryTop = Bodies.rectangle(VIEW.width / 2, -1, 27184, 1, {
  render: {
    strokeStyle: "transparent",
    fillStyle: "transparent",
  },
  isStatic: true,
});
const boundaryBottom = Bodies.rectangle(
  VIEW.width / 2,
  VIEW.height + 1,
  27184,
  1,
  {
    render: {
      strokeStyle: "transparent",
      fillStyle: "transparent",
    },
    isStatic: true,
  },
);

Composite.add(engine.world, [
  boundaryLeft,
  boundaryRight,
  boundaryTop,
  boundaryBottom,
]);

// Add Logo shape (shape gets scaled and positioned on first update)
const logoShape = Bodies.fromVertices(0, 0, [OpalVertices], {
  id: "svg",
  render: {
    strokeStyle: "transparent",
    fillStyle: "transparent",
  },
  isStatic: true,
});

const newCenter = {
  x:
    logoShape.bounds.min.x +
    (logoShape.bounds.max.x - logoShape.bounds.min.x) / 2,
  y:
    logoShape.bounds.min.y +
    (logoShape.bounds.max.y - logoShape.bounds.min.y) / 2,
};

Body.setCentre(logoShape, newCenter);

Composite.add(engine.world, logoShape);

// Add Logo sprite
const logoSprite = Bodies.rectangle(VIEW.width / 2, VIEW.height, 1, 1, {
  render: {
    sprite: {
      texture: LOGO_SPRITE_URL,
      xScale: scaleFactor * 0.75,
      yScale: scaleFactor * 0.75,
      yOffset: -0.155,
    },
  },
  isStatic: true,
  collisionFilter: {
    group: -1,
    category: 2,
    mask: 0,
  },
});

Composite.add(engine.world, logoSprite);

// Add buttons
var bodiesDom = document.querySelectorAll(".matter-body");
var bodies = [];
function createButtons() {
  bodies = [];
  for (var i = 0; i < bodiesDom.length; i++) {
    var body = Bodies.capsule(
      VIEW.width * 0.1 + Math.floor(Math.random() * VIEW.width * 0.8),
      VIEW.centerY / 2 + Math.floor((Math.random() * VIEW.height) / 4),
      (VIEW.height * (bodiesDom[i].offsetHeight / 2)) / VIEW.height,
      (VIEW.width * (bodiesDom[i].offsetWidth * 0.82)) / VIEW.width,
      {
        friction: 0.2,
        frictionStatic: 10,
        restitution: 0.6,
        render: {
          strokeStyle: "#181818",
          fillStyle: "transparent",
          lineWidth: isMedium ? 2 : 1.5,
        },
      },
      Math.PI / 2,
    );

    bodiesDom[i].id = body.id;

    bodies.push(body);
  }

  bodiesDom.forEach((bodyDom, index) => {
    document.getElementById(bodyDom.id).addEventListener("mouseover", () => {
      bodies[index].render.fillStyle = "#181818";
    });
    document.getElementById(bodyDom.id).addEventListener("mouseout", () => {
      bodies[index].render.fillStyle = "transparent";
    });
  });

  Composite.add(engine.world, bodies);
}

createButtons();

function toss() {
  for (var i = 0; i < bodies.length; i++) {
    Body.setVelocity(bodies[i], {
      x: Math.floor(Math.random() * 40) - 20,
      y: -20,
    });
  }
}

window.toss = toss;

window.requestAnimationFrame(update);

function update() {
  // strips
  for (var i = 0, l = bodiesDom.length; i < l; i++) {
    var bodyDom = bodiesDom[i];
    var body = null;
    for (var j = 0, k = bodies.length; j < k; j++) {
      if (bodies[j].id == bodyDom.id) {
        body = bodies[j];
        break;
      }
    }

    if (body === null) continue;

    if (
      body.position.x < 0 ||
      body.position.x > VIEW.width ||
      body.position.y < 0 ||
      body.position.y > VIEW.height
    )
      Body.setPosition(body, {
        x: VIEW.width * 0.1 + Math.floor(Math.random() * VIEW.width * 0.8),
        y: VIEW.centerY / 2 + Math.floor((Math.random() * VIEW.height) / 4),
      });

    bodyDom.style.position = "absolute";

    bodyDom.style.transform =
      "translate( " +
      (body.position.x - bodyDom.offsetWidth / 2) +
      "px, " +
      (body.position.y - bodyDom.offsetHeight / 2) +
      "px )";
    bodyDom.style.transform += "rotate( " + body.angle + "rad )";
  }

  window.requestAnimationFrame(update);
}

function handleResize(newCanvas) {
  // Update constraints
  VIEW.width = newCanvas.getBoundingClientRect().width;
  VIEW.height = newCanvas.getBoundingClientRect().height;
  VIEW.centerX = VIEW.width / 2;
  VIEW.centerY = VIEW.height / 2;

  scaleFactor = (VIEW.width * 0.98) / LOGO_LINE_WIDTH;

  // Update render dimensions
  render.canvas.width = VIEW.width * window.devicePixelRatio;
  render.canvas.height = VIEW.height * window.devicePixelRatio;
  render.options.width = VIEW.width;
  render.options.height = VIEW.height;
  Render.setPixelRatio(render, window.devicePixelRatio);

  // Reposition right wall
  Body.setPosition(boundaryRight, {
    x: VIEW.width,
    y: VIEW.height / 2,
  });

  // Re-scale svg
  const { min, max } = logoShape.bounds;
  const bodyWidth = max.x - min.x;
  const newScaleFactor = (VIEW.width * 0.98) / bodyWidth;
  Body.scale(logoShape, newScaleFactor, newScaleFactor);
  Body.setPosition(logoShape, {
    x: VIEW.width / 2,
    y: VIEW.height - (LOGO_LINE_HEIGHT / 2.55) * scaleFactor,
  });

  // Re-scale sprite
  Body.setPosition(logoSprite, {
    x: VIEW.width / 2,
    y: VIEW.height - (LOGO_LINE_HEIGHT / 2.55) * scaleFactor,
  });
  logoSprite.render.sprite.xScale = scaleFactor * 0.75;
  logoSprite.render.sprite.yScale = scaleFactor * 0.75;

  // Re-create buttons
  var newIsLarge = newCanvas.getBoundingClientRect().width >= 1024;
  var newIsMedium = newCanvas.getBoundingClientRect().width >= 768;
  if (newIsLarge !== isLarge || newIsMedium !== isMedium) {
    for (var j = 0, k = bodies.length; j < k; j++) {
      if (bodies[j].isStatic === true) return;
      Composite.remove(engine.world, bodies[j]);
    }
    isLarge = newIsLarge;
    isMedium = newIsMedium;
    createButtons();
  }
}

// This makes sure vertices get scaled correctly
handleResize(canvas);

window.addEventListener("resize", () => handleResize(canvas));

// Add trigger for tossing the buttons when a user scrolls to the bottom
var isScrolled = false;
window.addEventListener("scroll", () => {
  const { bottom } = canvas.getBoundingClientRect();
  const { innerHeight } = window;
  if (bottom < innerHeight + 100 && !isScrolled) {
    toss();
    isScrolled = true;
  }
});

Render.run(render);
Runner.run(runner, engine);

const OpalVertices = [
  {
    x: 1283.6905787398478,
    y: 141.1998620370843,
  },
  {
    x: 316.25478160437,
    y: 141.1998620370843,
  },
  {
    x: 316.25478160437,
    y: 91.37794139843672,
  },
  {
    x: 316.72489926480955,
    y: 85.74193604268416,
  },
  {
    x: 317.5417150070336,
    y: 79.78143609374504,
  },
  {
    x: 318.7647677687277,
    y: 73.89143802943295,
  },
  {
    x: 320.4960314663481,
    y: 68.1312928909591,
  },
  {
    x: 322.8536788156513,
    y: 62.59947378111967,
  },
  {
    x: 325.9494403265679,
    y: 57.44653774424013,
  },
  {
    x: 329.83925413164224,
    y: 52.865585115566375,
  },
  {
    x: 334.46955119348445,
    y: 49.03497085815334,
  },
  {
    x: 339.67932944657446,
    y: 46.03722395250876,
  },
  {
    x: 345.2731897558143,
    y: 43.83233776391435,
  },
  {
    x: 351.090310241194,
    y: 42.30477694159609,
  },
  {
    x: 357.02502415165355,
    y: 41.32207522424994,
  },
  {
    x: 363.0155060513333,
    y: 40.76698653883934,
  },
  {
    x: 369.0279453343223,
    y: 40.54576325426912,
  },
  {
    x: 370.95058578375483,
    y: 40.5331069302517,
  },
  {
    x: 388.27994948415403,
    y: 40.5331069302517,
  },
  {
    x: 393.09547311875355,
    y: 40.616057987426565,
  },
  {
    x: 399.10019541212455,
    y: 40.989965641363945,
  },
  {
    x: 405.0689402827495,
    y: 41.74150082601492,
  },
  {
    x: 410.9559153621339,
    y: 42.976018464629384,
  },
  {
    x: 416.68003946608223,
    y: 44.81939771828643,
  },
  {
    x: 422.1094489361847,
    y: 47.40059646712556,
  },
  {
    x: 427.06189244118997,
    y: 50.805394490746224,
  },
  {
    x: 431.34921499211384,
    y: 55.0162678875308,
  },
  {
    x: 434.85726390374475,
    y: 59.89668424330427,
  },
  {
    x: 437.58920676848993,
    y: 65.2524288270862,
  },
  {
    x: 439.63416770083086,
    y: 70.90810768611529,
  },
  {
    x: 441.11020149256746,
    y: 76.73940199983852,
  },
  {
    x: 442.1284585021951,
    y: 82.66854507930377,
  },
  {
    x: 442.7803306567301,
    y: 88.6495242017621,
  },
  {
    x: 442.97575366353885,
    y: 91.37794139843672,
  },
  {
    x: 450.25649327377596,
    y: 91.37794139843672,
  },
  {
    x: 450.25649327377596,
    y: 64.72201042872553,
  },
  {
    x: 470.77494126626254,
    y: 64.72201042872553,
  },
  {
    x: 470.77494126626254,
    y: 80.30640347873725,
  },
  {
    x: 473.76291924806276,
    y: 75.75724865758187,
  },
  {
    x: 477.85798271201406,
    y: 71.36024477652555,
  },
  {
    x: 482.69889023857894,
    y: 67.80139732318268,
  },
  {
    x: 488.09845088863614,
    y: 65.16245772593933,
  },
  {
    x: 493.8466303320242,
    y: 63.40119141936695,
  },
  {
    x: 499.7764000439004,
    y: 62.40039977975815,
  },
  {
    x: 505.7796946253314,
    y: 62.02552439980033,
  },
  {
    x: 507.05829651397323,
    y: 62.01429735053816,
  },
  {
    x: 509.8863523956356,
    y: 62.01429735053816,
  },
  {
    x: 511.7966695333903,
    y: 62.03367291649986,
  },
  {
    x: 517.8020391173836,
    y: 62.384424096313445,
  },
  {
    x: 523.7465909271598,
    y: 63.29606407715895,
  },
  {
    x: 529.5237469550027,
    y: 64.95866906167618,
  },
  {
    x: 534.9360514261066,
    y: 67.56729388144046,
  },
  {
    x: 539.7041502344437,
    y: 71.21850190237645,
  },
  {
    x: 543.5873981713436,
    y: 75.80058040356883,
  },
  {
    x: 546.5275482622242,
    y: 81.0417568425554,
  },
  {
    x: 548.6340063943999,
    y: 86.6732908432289,
  },
  {
    x: 549.8401631493336,
    y: 91.37794139843672,
  },
  {
    x: 559.7082730342831,
    y: 91.37794139843672,
  },
  {
    x: 560.1092847241707,
    y: 88.07794362867767,
  },
  {
    x: 561.549446679575,
    y: 82.24322923329746,
  },
  {
    x: 564.0904617643014,
    y: 76.8020652409363,
  },
  {
    x: 567.8103170215564,
    y: 72.0910932730551,
  },
  {
    x: 572.5278445285063,
    y: 68.37644618041725,
  },
  {
    x: 577.9031111220149,
    y: 65.69068359946864,
  },
  {
    x: 583.6383263901018,
    y: 63.88612456797519,
  },
  {
    x: 589.5487967418626,
    y: 62.77261943187897,
  },
  {
    x: 595.5360972769944,
    y: 62.18913513834278,
  },
  {
    x: 601.5498219598007,
    y: 62.0143506028163,
  },
  {
    x: 601.5875686435811,
    y: 62.01429735053816,
  },
  {
    x: 617.4728187022804,
    y: 62.01429735053816,
  },
  {
    x: 619.6011873924863,
    y: 62.03352601366359,
  },
  {
    x: 625.6101928216773,
    y: 62.32385192527574,
  },
  {
    x: 631.5793692193838,
    y: 63.067230774668026,
  },
  {
    x: 637.4343653875969,
    y: 64.43713185791093,
  },
  {
    x: 643.0211169767754,
    y: 66.65178163364506,
  },
  {
    x: 648.0691024139218,
    y: 69.90480722150576,
  },
  {
    x: 652.2559434248348,
    y: 74.2079080551881,
  },
  {
    x: 655.4111592680193,
    y: 79.31933945010682,
  },
  {
    x: 657.6116535782644,
    y: 84.91320664541715,
  },
  {
    x: 659.054716864685,
    y: 90.7515385231406,
  },
  {
    x: 659.1716001063658,
    y: 91.37794139843672,
  },
  {
    x: 669.4007384017402,
    y: 91.37794139843672,
  },
  {
    x: 669.4007384017402,
    y: 40.71362113546419,
  },
  {
    x: 689.7988435907517,
    y: 40.71362113546419,
  },
  {
    x: 689.7988435907517,
    y: 91.43811280017421,
  },
  {
    x: 698.8847252531139,
    y: 91.43811280017421,
  },
  {
    x: 699.4965168050571,
    y: 88.83722011938289,
  },
  {
    x: 701.3553151181309,
    y: 83.1185038760949,
  },
  {
    x: 704.0272940813948,
    y: 77.73505433553457,
  },
  {
    x: 707.6502485552732,
    y: 72.94402720795493,
  },
  {
    x: 712.2027307256701,
    y: 69.02758382077089,
  },
  {
    x: 717.4635049211582,
    y: 66.12626657651636,
  },
  {
    x: 723.146220788132,
    y: 64.16542817615323,
  },
  {
    x: 729.037612134032,
    y: 62.957133984951724,
  },
  {
    x: 735.0184673072222,
    y: 62.312411866900966,
  },
  {
    x: 741.0302088017388,
    y: 62.08147142685225,
  },
  {
    x: 742.3284773075868,
    y: 62.07446875227565,
  },
  {
    x: 757.130642135011,
    y: 62.07446875227565,
  },
  {
    x: 759.0815558715698,
    y: 62.08998720064246,
  },
  {
    x: 765.091938514851,
    y: 62.35472906517491,
  },
  {
    x: 771.0690843914252,
    y: 63.033075865234515,
  },
  {
    x: 776.9554075894737,
    y: 64.26697476172706,
  },
  {
    x: 782.6358464624852,
    y: 66.2348507260904,
  },
  {
    x: 787.9065365994219,
    y: 69.11922135096674,
  },
  {
    x: 792.4831842863857,
    y: 73.00748923322494,
  },
  {
    x: 796.1215268323638,
    y: 77.78605027793272,
  },
  {
    x: 798.7633172627735,
    y: 83.18340738544585,
  },
  {
    x: 800.5272163436148,
    y: 88.93107725962057,
  },
  {
    x: 801.0557654033839,
    y: 91.43811280017421,
  },
  {
    x: 811.1645608952833,
    y: 91.43811280017421,
  },
  {
    x: 811.6470632610227,
    y: 86.08403143194171,
  },
  {
    x: 813.2140758155488,
    y: 80.28576730281179,
  },
  {
    x: 816.0545156063089,
    y: 74.99869290891712,
  },
  {
    x: 820.1213002742777,
    y: 70.58513032378461,
  },
  {
    x: 825.0961280989601,
    y: 67.21994901114296,
  },
  {
    x: 830.6098689785106,
    y: 64.8270303482345,
  },
  {
    x: 836.4050022409425,
    y: 63.22102656746197,
  },
  {
    x: 842.3367918668175,
    y: 62.22317298457864,
  },
  {
    x: 848.3293992669096,
    y: 61.69469003108585,
  },
  {
    x: 854.3436381096428,
    y: 61.5331588858194,
  },
  {
    x: 854.4277987445438,
    y: 61.532926136638174,
  },
  {
    x: 870.1325345980305,
    y: 61.532926136638174,
  },
  {
    x: 872.395095356601,
    y: 61.55059441619957,
  },
  {
    x: 878.4068368511178,
    y: 61.78836813349181,
  },
  {
    x: 884.3934946863408,
    y: 62.381389634601675,
  },
  {
    x: 890.3109612856791,
    y: 63.458802580111026,
  },
  {
    x: 896.0626480342831,
    y: 65.21015294523497,
  },
  {
    x: 901.4464533551501,
    y: 67.87609971078442,
  },
  {
    x: 906.104301584864,
    y: 71.66058762528607,
  },
  {
    x: 909.6308877981471,
    y: 76.51466131855189,
  },
  {
    x: 911.8829082782152,
    y: 82.08137903343348,
  },
  {
    x: 913.0468194500075,
    y: 87.97817364928001,
  },
  {
    x: 913.3356010455534,
    y: 91.43811280017421,
  },
  {
    x: 922.240968502703,
    y: 91.43811280017421,
  },
  {
    x: 923.6895259552002,
    y: 85.95533995665276,
  },
  {
    x: 925.9317773966559,
    y: 80.37750812406439,
  },
  {
    x: 929.0678591454159,
    y: 75.25265118712439,
  },
  {
    x: 933.1709655396533,
    y: 70.86729624191315,
  },
  {
    x: 938.1129973061376,
    y: 67.45384587077167,
  },
  {
    x: 943.6170793242032,
    y: 65.04142585634794,
  },
  {
    x: 949.4242953449185,
    y: 63.48160006401517,
  },
  {
    x: 955.3704079973302,
    y: 62.57544369060492,
  },
  {
    x: 961.3715357619261,
    y: 62.15323116701488,
  },
  {
    x: 965.6847205571758,
    y: 62.07446875227565,
  },
  {
    x: 980.4868853846002,
    y: 62.07446875227565,
  },
  {
    x: 985.4375256572902,
    y: 62.176620852977756,
  },
  {
    x: 991.4370374906871,
    y: 62.622535231057746,
  },
  {
    x: 997.3842519143707,
    y: 63.524628822902315,
  },
  {
    x: 1003.2068192814766,
    y: 65.02792686290853,
  },
  {
    x: 1008.7681934056887,
    y: 67.30891015529045,
  },
  {
    x: 1013.8367819656225,
    y: 70.5333470739983,
  },
  {
    x: 1018.1132704323712,
    y: 74.7488226612005,
  },
  {
    x: 1021.3709877295654,
    y: 79.79429468263257,
  },
  {
    x: 1023.5915710026709,
    y: 85.37834693219439,
  },
  {
    x: 1024.9225106993058,
    y: 91.24165229208427,
  },
  {
    x: 1024.9535512686105,
    y: 91.43811280017421,
  },
  {
    x: 1033.257204708385,
    y: 91.43811280017421,
  },
  {
    x: 1034.0915981281332,
    y: 88.00686101877598,
  },
  {
    x: 1036.0555421462693,
    y: 82.32386970898418,
  },
  {
    x: 1038.8572730396716,
    y: 77.00736884069852,
  },
  {
    x: 1042.6194546766278,
    y: 72.32536657120176,
  },
  {
    x: 1047.2902303559338,
    y: 68.55051456461689,
  },
  {
    x: 1052.6280918147563,
    y: 65.79235872502423,
  },
  {
    x: 1058.351205961705,
    y: 63.951530760469005,
  },
  {
    x: 1064.2609601621393,
    y: 62.83477310376343,
  },
  {
    x: 1070.2487197686344,
    y: 62.25717410510543,
  },
  {
    x: 1076.2623342743134,
    y: 62.075471364133215,
  },
  {
    x: 1076.700956762858,
    y: 62.07446875227565,
  },
  {
    x: 1091.5031215902823,
    y: 62.07446875227565,
  },
  {
    x: 1094.31346098989,
    y: 62.106809870752556,
  },
  {
    x: 1100.321052479282,
    y: 62.42636256071262,
  },
  {
    x: 1106.28898020288,
    y: 63.18071321568907,
  },
  {
    x: 1112.1525334613061,
    y: 64.51695289121406,
  },
  {
    x: 1117.7853758153653,
    y: 66.61617146798972,
  },
  {
    x: 1122.9698707131186,
    y: 69.65161329711847,
  },
  {
    x: 1127.4205124922692,
    y: 73.68360265110184,
  },
  {
    x: 1130.916285835644,
    y: 78.56787405864907,
  },
  {
    x: 1133.4300871699509,
    y: 84.0266939356448,
  },
  {
    x: 1135.0936148879084,
    y: 89.80480024120988,
  },
  {
    x: 1135.428244858655,
    y: 91.43811280017421,
  },
  {
    x: 1145.1158405383921,
    y: 91.43811280017421,
  },
  {
    x: 1145.1158405383921,
    y: 64.48132482177553,
  },
  {
    x: 1163.528289470066,
    y: 64.48132482177553,
  },
  {
    x: 1163.528289470066,
    y: 85.30062982294952,
  },
  {
    x: 1163.790937051039,
    y: 84.67429672650061,
  },
  {
    x: 1166.544190008471,
    y: 79.33020107856377,
  },
  {
    x: 1170.118997077761,
    y: 74.4995675691108,
  },
  {
    x: 1174.5016962951352,
    y: 70.38949935764825,
  },
  {
    x: 1179.5634538731822,
    y: 67.1511847116262,
  },
  {
    x: 1185.1006624808274,
    y: 64.81249614887071,
  },
  {
    x: 1190.9186024085907,
    y: 63.29420483813737,
  },
  {
    x: 1196.8757327737228,
    y: 62.46617552469947,
  },
  {
    x: 1202.88508709715,
    y: 62.195867419886355,
  },
  {
    x: 1203.301586018552,
    y: 62.19481155575065,
  },
  {
    x: 1206.0092990967391,
    y: 62.19481155575065,
  },
  {
    x: 1208.901860013809,
    y: 62.236906104413364,
  },
  {
    x: 1214.9039426468407,
    y: 62.638963099795355,
  },
  {
    x: 1220.8403597120582,
    y: 63.60181019586615,
  },
  {
    x: 1226.5957190315692,
    y: 65.33637232123225,
  },
  {
    x: 1231.9463610371474,
    y: 68.06616673199693,
  },
  {
    x: 1236.5669693978662,
    y: 71.89911192425738,
  },
  {
    x: 1240.1981137048667,
    y: 76.6820318515602,
  },
  {
    x: 1242.821871812124,
    y: 82.08840741600642,
  },
  {
    x: 1244.6059333072437,
    y: 87.83052252668455,
  },
  {
    x: 1245.361395833062,
    y: 91.37794139843672,
  },
  {
    x: 1262.991616542149,
    y: 91.37794139843672,
  },
  {
    x: 1262.991616542149,
    y: 81.08863170132473,
  },
  {
    x: 1250.776821989437,
    y: 81.08863170132473,
  },
  {
    x: 1250.776821989437,
    y: 64.42115342003804,
  },
  {
    x: 1262.991616542149,
    y: 64.42115342003804,
  },
  {
    x: 1262.991616542149,
    y: 40.71362113546419,
  },
  {
    x: 1283.2693789276852,
    y: 40.71362113546419,
  },
  {
    x: 1283.2693789276852,
    y: 64.48132482177553,
  },
  {
    x: 1341.33478160437,
    y: 64.48132482177553,
  },
  {
    x: 1341.33478160437,
    y: 81.14880310306222,
  },
  {
    x: 1283.4498931328978,
    y: 81.14880310306222,
  },
  {
    x: 1283.4498931328978,
    y: 91.43811280017421,
  },
];

export default OpalVertices;
